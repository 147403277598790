import React,{useState ,useEffect} from 'react'
import {Link , useParams , useHistory} from "react-router-dom";

import CardItem from '../components/Card/CardItem';
import "../components/Card/Cards.css"
import axios from "../axios"
import {Row , Col} from "react-bootstrap"
import Loader from "../components/Loader/Loader"


const Products = () => {
 

 const history = useHistory();
 const {id , name} = useParams();
 
  
   const [products , setProducts] = useState([])
   const [loading , setLoading] = useState(true)
  useEffect(()=>{
    const getProducts = async () => {
      
      try {
         const res = await axios.get(`/service/?category=${id}`);
         const {data} = res.data;
          if(data.length === 0){
            history.push("/")
          }
          // console.log(res.data)
          setProducts(data)
          setLoading(false)
         
      } catch (err) {
          console.log(err)
        
      }
  }
  getProducts()
  },[history , id])
 
 
 
 
 
//   let columns = [];
//   products.map((item , index)=>{
                  
                  
//    columns.push(
//      <div className="col-md-4"  >
// <CardItem key={item._id}
// {...item} props="product" category={name}
// />
// </div>
//   )

//   if((index + 1)%3===0)
   
//    {
//         columns.push(<div className = "w-100"> <br /> </div>)
//        }
//    })
   if(loading) {
     return (<Loader/>)
   }


    return (
         <>
        <div className="page-title titl-2">
        <div className="container">
            <div className="row">
                <h2>Services</h2>
                <p><Link style={{color:"#fff" , textDecoration:"none" }} to ="/services"> Solutions</Link> <i className="fas fa-angle-double-right"></i> {name} </p>
            </div>
        </div>
    </div>
    
    <div className="session-title row">
                <h1>{name}</h1>
                <div className="underline"></div>
                </div>
      
      <br />

       
       

          <div className="cards">
        {/* <div className='key-row row '> */}
          <Row >
            {
              products.map((item , index)=>(
                <Col lg={3} md={4}>
                  <CardItem key={item._id}
{...item} props="product" category={name} />
                  </Col>
              ))
            }
            </Row>
        {/* {columns} */}
       {/* </div> */}
       </div>
       
        </>
    )
}

export default Products
