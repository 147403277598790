import React from 'react'
import "../../App.css"
import data from "../data"
import {useLocation} from "react-router-dom";
import {Col } from "react-bootstrap";
// import Aos from "aos";
// import "aos/dist/aos.css"

const Features = () => {
   
    const location = useLocation();
    const current = location.pathname;
   
   
    
       if(current === "/about")
       {
        return (

       
        

            <div className="key-features container-fluid" >
        <div className="container" >
            <div className="session-title row " >
                <h1 > Our Mission</h1>
                <h3>To prosper jointly with our esteemed clients and stakeholders and to contribute solely to a prosperous society</h3>
            </div>
            
           <div className=" key-row row">
             {data.map((role )=>(
                 <Col lg={4} md={4} sm={1} >
                      {/* <div class="col-md-3" key={role.id}> */}
                   <div className="key-single">
                       <i className="fa fa-check-square"></i>
                       <h6>{role.name}</h6>
                           <p>{role.desc}</p>

 {/* </div> */}
                 </div>
                 </Col>
             ))}
                  </div>
             </div>
           </div>
        )
       }
      
       else{
           return (
            <div className="key-features container-fluid">
            <div className="container">
                <div className="session-title row">
                    <h1>Key Features</h1>
                    <h2>Innovative Solutions That Improves Effiecency And Effectiveness </h2>
                </div>
                <div className="key-row row">
                    <div className="col-md-4">
                        <div  className="key-single">
                            <i  className="fas fa-fingerprint"></i>
                            <h6>Quality</h6>
                            <p>Think of quality in service delivery, think of REXIAM GLOBAL SERVICES LIMITED</p>
                        </div>
                    </div>
                    <div  className="col-md-4">
                        <div  className="key-single">
                            <i  className="fas fa-user-lock"></i>
                            <h6>Full Device Protection</h6>
                     
                            <p>High security for protecction of personal Data on devices ,Covers loss, theft and accidental damage </p>
                    </div>
                    </div>
                    <div  className="col-md-4">
                        <div  className="key-single">
                            <i  className="fas fa-tools"></i>
                            <h6>Expertise</h6>
                            <p>Our professionals in various fields are trained to deliver services that exceed clients' expectations.</p>
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
           )
       }

       

    





    
            
               
                
       
      
    

    
}

export default Features
