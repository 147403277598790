import React from 'react'
import {Link} from "react-router-dom";



const Interact = () => {
    return (
        <>
            <section className="project_area">
    <div className="container">
        <div className="project_inner">
            <div className="center_title">
                <h2> Ready To Discuss Your Project? </h2>
                <p>We want to get in touch with you. Simply send a message or place a call and let's discuss business</p>
                <Link  to="/sign-up" className="primary link">Get in Touch</Link>
            </div>
           
        </div>
    </div>

</section>
        </>
    )
}

export default Interact
