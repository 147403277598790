

export default [
  {
    title: "About Us",
    description: "Innovative solutions  to increase business efficiency.",
    urls: "/images/head_bg.jpg",
  },
  {
    title: "Services",
    description: "We offer IT Solutions, Cyber Security,Tracking System",
    urls: "/images/accessControl.jpg",
  },
  {
    title: "Contact Us",
    description: "Alternate power supply   installations And lots more...",
    urls: "/images/background.jpg" ,
  },
 
];
