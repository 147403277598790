


 const data = [
    {
        id:1,
        name:"Vision",
        desc:"To be one of the leading indigenous service-providing companies motivated solely by creativity and innovation."
 
     },
    {
       id:2,
       name:"BUSINESS DOMINANCE",
       desc:"To extend logistical-centered based operations to support services vital to our esteem clients and stakeholders."

    },
    {
        id:3,
        name:"ACTIVE GUIDELINE",
        desc:"To deliver customer satisfaction with creativity innovation, sincerity and gratitude and to act in compliance with the laws and ethics governing us."
 
     },
     {
        id:4,
        name:"MANAGEMENT POLICY",
        desc:"To take up challenges of creativity and innovation to meet the market demand of customers as a multi-faceted service-providing company"
 
     },
     {
        id:5,
        name:"COMMITMENT",
        desc:"We develop relationships that make a positive and long-lasting difference to our customers."
 
     },
     {
        id:6,
        name:"Accountability",
        desc:"We are accountable for delivering on our commitments."
 
     },
     {
        id:7,
        name:"Integrity",
        desc:"We uphold the highest level of integrity in all our actions."
 
     },
     {
        id:8,
        name:"Quality",
        desc:"We provide outstanding products and unsurpassed services that together deliver premium values to our esteemed customers."
 
     },
    
    
     {
        id:11,
        name:"Teamwork",
        desc:"We work together across boundaries to meet the standard needs of our esteem and to help our Company Grow."
 
     },
    
]
export default data



    



