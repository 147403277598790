import React,{useState} from 'react';
import './Footer.css';
import { Button } from '../Button/Button';
import { Link } from 'react-router-dom';
import validator from 'validator';
import swal from "sweetalert";
import emailjs from 'emailjs-com';
import process from 'process';

function Footer() {
 
  const [error , setError] =  useState("");
  const [button , setButton] = useState("Submit")
 
        
    
  const handleForm =  (e) => {
      e.preventDefault();
      let email = e.target.email.value;
     
     
      setButton("Submitting...")

      if(!validator.isEmail(email)){
                setError("Invalid email")
                return
      }
    
       
      emailjs.sendForm("service_2s6gh8j", process.env.REACT_APP_TEMPLATE_ID3, e.target, process.env.REACT_APP_API_KEY2)
        .then(() => {

          swal("Success","Email Subscribed","success");
        }, (error) => {

            swal("Danger" , "Opps something went wrong, try again" , "danger")
        })
   
        e.target.reset();
        setError('')
        
      setButton("Submit");
     
      
  }

  return (
    <div className='footer-container'>
      <div className="row">
       <div className="col-lg-6 col-md-5">
       <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join the Adventure newsletter to receive our best Product deals
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form onSubmit={handleForm}>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
              required
            />
            <Button buttonStyle='btn--outline' type="submit" name="button">{button}</Button>
            <span style={{fontWeight:"bold" , color:"red"}} >{error}</span>
            
          </form>
        </div>
      </section>
         </div>
         <div className="col-lg-3 col-md-3">
         <div className='footer-link-items'>
            <h2>About Us</h2>
            <Link to='/about'>Mission</Link>
            <Link to='/about'>Vision</Link>
            <Link to='/about'>Careers</Link>
           
            <Link to='/about'>Terms of Service</Link>
          </div>
           </div>
           <div className="col-lg-3 col-md-4">
           <div className='footer-link-items'>
           
            <h2>Contacts</h2>
            
            <p>+234 803 933 0949, +234 905 697 8554</p>
           
            <p>admin@rexiamglobalservices.com</p>
            <p>info@rexiamglobalservices.com</p>
          </div>
           </div>
       </div>

     
      {/* <div className='footer-links'>
        <div className='footer-link-wrapper'>
        
         
        </div>
        <div className='footer-link-wrapper'>
          
          <div className='footer-link-items'>
            <h2>Socials</h2>
            <a href="instagram.com">Instagram</a>
            <a href="https://facebkook.com" target="_blank">Facebook</a>
           
            <a href="twitter.com">Twitter</a>
          </div>
          <div className='footer-link-items'>
            <h2>Contacts</h2>
            
            <p>+234 803 933 0949, +234 905 697 8554</p>
           
            <p>rexiamgsltd@gmail.com </p>

          </div>
         
        </div> */}
      
       
      <section className='social-media'>
        <div className='social-media-wrap'>
          
          <small className='website-rights'> © 2019 Rexiam Global Services(LLC)</small>
          <div className='social-icons'>
            <a
              className='social-icon-link facebook'
              href='https://facebkook.com'
              target="_blank"
             
              aria-label='Facebook'
            >
              <i className='fab fa-facebook-f' />
            </a>
            <a
              className='social-icon-link instagram'
              href='https://www.instagram.com/rexiamgsltd'
              target="_blank"
              
              aria-label='Instagram'
            >
              <i className='fab fa-instagram' />
            </a>
           
            <a
              className='social-icon-link twitter'
              href='https://twitter.com/RexiamLtd?t=RH8jc64Z3tAFQXBC7N-jJA&s=09'
              target="_blank"
             
              aria-label='Twitter'
            >
              <i className='fab fa-twitter' />
            </a>
            <a
              className='social-icon-link '
              href='https://wa.me/message/LVKML64LAFDRM1'
              target="_blank"
             
              aria-label='Twitter'
            >
              <i className='fab fa-whatsapp' />
            </a>
            <a
              className='social-icon-link twitter'
              href='https://facebkook.com'
              target="_blank"
           
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
