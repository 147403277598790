import React,{useEffect, useState} from 'react';

import './App.css';
import Home from './pages/Home';
import {BrowserRouter as Router, Switch, Route , Redirect} from "react-router-dom"
// import Services from './pages/Services';

import SignUp from './pages/SignUp';
import Footer from "./components/Footer/Footer";
import About from './pages/About';
import Contact from './pages/Contact';
import ServiceItem from './pages/ServiceItem';
import Products from './pages/Products';
import Nav from "./components/Nav/Nav"
// import ScrollToTop from './components/ScrollToTop';
import ScrollRestoration from "react-scroll-restoration";
import Announcement from './components/Announcement/Announcement';
import Loader from './components/Loader/Loader';
import axios from "./axios"

function App() {
  const [time , setTime] = useState(false)
  const [loading , setLoading] = useState(true)
  const [data , setData] = useState([])
  // useEffect(()=>{
  //   if(!loading)
  //   {
  //     const timer =  setTimeout(()=>
  //     setTime(true)
  //     // console.log(time)
  //    ,15000 )
  //    return () =>  clearTimeout(timer)
  //   }
   
  // },[loading])


  useEffect(()=>{
    const getCategories = async () => {
      
      try {
         const res = await axios.get("/service/");
          
          const {data} = res.data
           setData(data)
          setLoading(false)
         
      } catch (err) {
          console.log(err)
        
      }
  }
  getCategories()
  },[])
  

  if(loading){
    return <Loader/>
  }
 
  return (
    <>
      <Router>
     
        {/* // <SweetAlert> */}
        {/* {time &&  */}
             <Announcement time={time} setTime={setTime}  data={data}/>
         {/* } */}
               
        
     
        {/* // </SweetAlert> */}
  
        <Nav  />
       
        {/* <ScrollToTop /> */}
       
      
        <ScrollRestoration />
        <div className="app">
        <Switch>
          <Route  exact path='/'  >
                  <Home   />
                
             
            </Route>
            <Route path="/about"  >
            <About />
            </Route>
          <Route path="/contact"  >
           <Contact />
            </Route >
          <Route path='/sign-up' >
            <SignUp/>
            </Route>
          <Route path="/category/:id/:name">
                 <Products />
              </Route>
             <Route path='/product/:id'  >
               <ServiceItem/>
              </Route>
            <Route render={()=> <Redirect to={{pathname:"/"}}/>}/>
          </Switch>
          </div>
        <Footer />
      </Router>
    </>
  );
}

export default App;
