
import React ,{ useState , useEffect } from "react";

import styled from "styled-components";
import {Link} from "react-scroll"
import {Link as LinkR} from "react-router-dom"




const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  overflow: hidden;
  box-shadow: white;
  object-fit: contain;
  margin: 1rem auto;
 
  
  @media screen and (max-width: 1300px)
   {
     height:60vh;
     
   }

  @media screen and (max-width: 660px) {

    height: 60vh;
     
}
  
`;

const Arrow = styled.div`
  width: 50px;
  height: 50px;
  background-color: #fff7f7;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: ${(props) => props.direction === "left" && "10px"};
  right: ${(props) => props.direction === "right" && "10px"};
  margin: auto;
  cursor: pointer;
  opacity: 0.5;
  z-index: 2;
  transition: all 1.5s ease;
`;

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  transition: all 1.2s ease;
  transform: translateX(${(props) => props.slideIndex * -100}vw);
  
  
 
`;

const Slide = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  align-items: center;

  
  background-color: #${(props) => props.bg};
  text-align:center;
  object-fit: cover;
  display:flex;
  flex-direction: column;

  
`;



const Image = styled.img`
  
  width: 100%;
  height: 100%;
  object-fit: cover;
 
  @media screen and (max-width: 1700px)
   {
    height: auto;
   }

  @media screen and (max-width: px) {
       
    height: auto;
  }
`;

const InfoContainer = styled.div`
  position:absolute;
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* top:30%;
  left:37%; */
  margin-top:200px;
  /* padding:20px; */
  width:350px;
  
  
@media screen and (max-width: 1300px)
   {
     margin-top:30px;
     width:300px;
   }
   @media screen and (max-width: 768px) {
       
       margin-top:100px;
       width:300px;
      
      }
      @media screen and (max-width: 1024px) {
       
       margin-top:180px;
       width:300px;
      
      }

  @media screen and (max-width: 660px) {
       
   margin-top:20px;
   width:300px;
  
  }
`;

const Title = styled.h1`
color: #fff;
 
  ;
  font-size: 45px;
`;

const Desc = styled.p`
color: #fff;
 
  
  font-size: 20px;
  font-weight: 500;
  
  letter-spacing: 3px;
`;



const Slider = () => {

  const [slideIndex, setSlideIndex] = useState(0);
  const [ over , setOver] = useState(false)
  useEffect(() => {
    let slider = setInterval(() => {
        setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
    }, 3000);
    return () => {
      clearInterval(slider);
    };
  }, [slideIndex]);

  const handleClick = (direction) => {
    if (direction === "left") {
      setSlideIndex(slideIndex > 0 ? slideIndex - 1 : 2);
    } else {
      setSlideIndex(slideIndex < 2 ? slideIndex + 1 : 0);
    }
  };
   const handleArrow = () => {
    if(window.innerWidth < 960)
    {
      setOver(false)
    }
    else{
      setOver(true)
    }
    
   }
  
   useEffect(() => {
     handleArrow();
   }, [over])
  

  return (
    <Container onMouseOver={handleArrow}>

      { over && (
    <Arrow direction="left" onClick={() => handleClick("left")}>
    <i className="fa  fa-arrow-left" />
    </Arrow>
      )}
      
      <Wrapper slideIndex={slideIndex}>
        
          <Slide bg="white" >
           
              <Image src="/images/head_bg.jpg" />
             
              <InfoContainer>
              <Title>About Us</Title>
              <Desc>Innovative solutions  to increase business efficiency.</Desc>
              <LinkR to ="/about"   className=" primary link">About us</LinkR>
              </InfoContainer>
             
            
            
          </Slide>

          <Slide bg="white" >
           
              <Image src="/images/accessControl.jpg" />
             
              <InfoContainer   >
              <Title>Services</Title>
              <Desc>We offer IT Solutions, Cyber Security,Tracking System</Desc>
              <Link to ="services" style={{cursor:"pointer"}}   className="primary link" smooth={true} duration={500} spy={true} exact="true">Services</Link>
              </InfoContainer>
            
            
          </Slide>
          <Slide bg="white" >
           
              <Image src="/images/background.jpg" />
              <InfoContainer>
              <Title>Services</Title>
              <Desc>Alternate power supply   installations And lots more...
                   </Desc>
              <Link to="services" style={{cursor:"pointer"}}  className="primary link" smooth={true} duration={500} spy={true} exact="true">Services</Link>
              </InfoContainer>
            
          </Slide>
       
       
      </Wrapper>
      {over && (
      <Arrow direction="right" onClick={() => handleClick("right")}>
      <i className="fa  fa-arrow-right" />
     </Arrow>
      )}
      
    </Container>
  );
};

export default Slider;
