import React from 'react'
import "./Loader.css"
const Loader = () => {
  return (
      <div className="spinner-overlay" >
    <div className="loadingio-spinner-dual-ball-zjrf5cn13e"><div className="ldio-d6s76xalcvj">
    <div></div><div></div><div></div>
    </div></div>
    {/* <div class="lds-dual-ring">

    <img src="/Logo/REX.png" alt="REXIAM " width="300" height="300" />
    </div> */}
    </div>
  )
}

export default Loader