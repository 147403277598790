import React from 'react';
import '../App.css';


import Cards from "../components/Card/Cards"
import Hero from '../components/Hero/Hero';
import HomeAbout from '../components/HomeAbout/HomeAbout';
import Features from '../components/Features/Features';
// import Services from "./Services";
import Interact from '../components/Interact/Interact';
import Carousal from '../components/Carousal/Carousal';
import Slider from '../components/Slider/Slider';





function Home() {
  
  return (
    <div className="app">
        {/* <Hero /> */}
       
        <Slider /> 
       
        <HomeAbout />
        <Cards  />
        <Features  />

       
       
        <Interact />

     
        <Carousal />
        {/* <Services /> */}
     
    </div>
  );
}

export default Home;
