import React from 'react'
import ProgressBar from "react-animated-progress-bar";
import "./progress.css"



const Progress = () => {
 
    return (
        
        <section className="with-medical" style={{backgroundColor:"#f8f8f8"}}>
        <div className="container">
            <div className="row">
                
                <div className="col-lg-6 col-md-12 txtr">
                
                <div>
                <h4><span>We Serve All Industries </span></h4>
                <h3> We measure our success by the results we give to our clients.</h3>
        <div style={{marginTop:"30px"}} >
            <h6>Alternate power supply  </h6>
            <ProgressBar 
      
        height="12px"
        rect
        fontColor="gray"
        percentage="95"
       
        rectBorderRadius="20px"
        trackPathColor="transparent"
        bgColor={false}
        trackBorderColor="grey"
        defColor={{
            fair: 'orangered',
            good: 'maroon',
            excellent: 'maroon',
            poor: 'red',
          }}
      />
      </div>
      <h6>Security  </h6>
       <ProgressBar 
       
        height="12px"
        rect
        fontColor="gray"
        percentage="97"
       
        rectBorderRadius="20px"
        trackPathColor="transparent"
        bgColor={false}
        trackBorderColor="grey"
        defColor={{
            fair: 'orangered',
            good: 'maroon',
            excellent: 'maroon',
            poor: 'red',
          }}
      />
       <h6>Networking </h6>
      <ProgressBar 
       
        height="12px"
        rect
        fontColor="gray"
        percentage="92"
       
        rectBorderRadius="20px"
        trackPathColor="transparent"
        bgColor={false}
        trackBorderColor="grey"
        defColor={{
            fair: 'orangered',
            good: 'maroon',
            excellent: 'maroon',
            poor: 'red',
          }}
      />
    <h6>Software Development </h6>
      <ProgressBar 
       
        height="12px"
        rect
        fontColor="gray"
        percentage="95"
       
        rectBorderRadius="20px"
        trackPathColor="transparent"
        bgColor={false}
        trackBorderColor="grey"
        defColor={{
            fair: 'orangered',
            good: 'maroon',
            excellent: 'maroon',
            poor: 'red',
          }}
      />
    
     {/* <div style={{margin:"0"}} >
    <p>Digital Marketing </p>
        <ProgressBar
        width="400px"
        height="12px"
        rect
        fontColor="gray"
        percentage="90"
       
        rectBorderRadius="20px"
        trackPathColor="transparent"
        bgColor="#333333"
        trackBorderColor="grey"
        defColor={{
            fair: 'orangered',
            good: 'maroon',
            excellent: 'maroon',
            poor: 'red',
          }}
      />
      </div>
      <div style={{margin:"0"}} >
      <p>Digital Marketing </p>
        <ProgressBar className="progress"
        width="400px"
        height="12px"
        rect
        fontColor="gray"
        percentage="60"
        // rectPadding="1px"
        rectBorderRadius="20px"
        trackPathColor="transparent"
        backgroundColor="transparent"
        trackBorderColor="grey"
        defColor={{
            fair: 'orangered',
            good: 'maroon',
            excellent: 'maroon',
            poor: 'red',
          }}
      />
      </div> */}
        </div>
                  















                </div>
              
                <div className="col-lg-6 col-md-12 ">
                   
                <img src ="images/options.svg" alt=""  style={{paddingTop:"70px" , paddingRight:"60px"}}/>
                </div>

            </div>
        </div>
    </section>
    )
}

export default Progress
