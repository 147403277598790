import React,{useState ,  useEffect, useCallback} from 'react'
import { FaTimes } from 'react-icons/fa'
import { FaChevronLeft, FaChevronRight,  } from 'react-icons/fa';
import "./Announcement.css"
import axios from  "../../axios"
import {Link} from "react-router-dom"

const Announcement = ({time , setTime , data}) => {

 

 



// const people =[
// {
//     id: 1,
//     name: 'susan smith',
//     job: 'web developer',
//     image:
//       'https://firebasestorage.googleapis.com/v0/b/rexiam.appspot.com/o/images%2Fcar_plate.jpg?alt=media&token=5399ef86-93bf-4cdb-88c9-5aa4eab6d654',
//     text:
//       "I'm baby meggings twee health goth +1. Bicycle rights tumeric chartreuse before they sold out chambray pop-up. Shaman humblebrag pickled coloring book salvia hoodie, cold-pressed four dollar toast everyday carry",
//   },
//   {
//     id: 2,
//     name: 'anna johnson',
//     job: 'web designer',
//     image:
//       'https://firebasestorage.googleapis.com/v0/b/rexiam.appspot.com/o/images%2Fintercome.jpg?alt=media&token=06a3c02c-dd34-4b28-ac67-2542841584dc',
//     text:
//       'Helvetica artisan kinfolk thundercats lumbersexual blue bottle. Disrupt glossier gastropub deep v vice franzen hell of brooklyn twee enamel pin fashion axe.photo booth jean shorts artisan narwhal.',
//   },
//   {
//     id: 3,
//     name: 'peter jones',
//     job: 'intern',
//     image:
//       'https://firebasestorage.googleapis.com/v0/b/rexiam.appspot.com/o/images%2Fclosed_circuit.jpg?alt=media&token=7f9009d8-464f-4ef8-9489-01e69dce5776',
//     text:
//       'Sriracha literally flexitarian irony, vape marfa unicorn. Glossier tattooed 8-bit, fixie waistcoat offal activated charcoal slow-carb marfa hell of pabst raclette post-ironic jianbing swag.',
//   },
//   {
//     id: 4,
//     name: 'bill anderson',
//     job: 'the boss',
//     image:
//       'https://firebasestorage.googleapis.com/v0/b/rexiam.appspot.com/o/images%2Finverter.jpg?alt=media&token=d77a3644-04ca-425d-a221-dda85c6be670',
//     text:
//       'Edison bulb put a bird on it humblebrag, marfa pok pok heirloom fashion axe cray stumptown venmo actually seitan. VHS farm-to-table schlitz, edison bulb pop-up 3 wolf moon tote bag street art shabby chic. ',
//   },

// ]

 

  
    const [index, setIndex] = useState(0);
    // console.log(data[0])
    const { title , _id , image  } = data[index];
    
    const checkNumber = (number) => {
      if (number > data.length - 1) {
        return 0;
      }
      if (number < 0) {
        return data.length - 1;
      }
      return number;
    };
    const nextPerson = () => {
      setIndex((index) => {
        let newIndex = index + 1;
        return checkNumber(newIndex);
      });
    };
    const prevPerson = () => {
      setIndex((index) => {
        let newIndex = index - 1;
        return checkNumber(newIndex);
      });
    };
    // const randomPerson = () => {
    //   let randomNumber = Math.floor(Math.random() * data.length);
    //   if (randomNumber === index) {
    //     randomNumber = index + 1;
    //   }
    //   setIndex(checkNumber(randomNumber));
    // };
  
    const innerFunction = useCallback(()=>{
      setIndex((index) => {
        let newIndex = index + 1;
        return checkNumber(newIndex);
      })
    },[])
  
    useEffect(()=>{
      const interval = setInterval(() => {
        
       innerFunction();
      }, 4000);
      return () => clearInterval(interval);
    },[innerFunction])

    




  return (
    
    <div className ={` modal-overlay ${time && "show-modal"} `}>
   <div className ={`modal-container  ${time && "show-modal-container"}`}>
  
   
<div className='title'>
          <h4>New Products</h4>
          <div className='underline'></div>
        </div>



<div className='img-container'>
<Link to ={`/product/${_id}`}  onClick={()=>setTime(false)}>
<img src={image} alt={title} className='person-img' />
 </Link>
</div>
<div>
<h4 className='author'>{title}</h4>


</div>
{/* <p className='info'>{text}</p> */}
<div className='button-container'>
<button className='prev-btn' onClick={prevPerson}>
  <FaChevronLeft />
</button>
<button className='next-btn' onClick={nextPerson}>
  <FaChevronRight />
</button>
</div>
{/* <button className='random-btn' onClick={randomPerson}>
surprise me
</button> */}
<button className="close-modal-btn" onClick={()=>setTime(false)} >
<FaTimes/>
</button>

   
        
       
      
    
   </div>
 </div>


  )
}

export default Announcement