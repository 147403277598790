import React , {useState, useRef} from 'react'
import emailjs from 'emailjs-com';
import '../App.css';

import validator from "validator"
import swal from 'sweetalert';
// import process from 'process';



const Contact = () => {
    // require('dotenv').config()
     const formRef = useRef()

        const [error , setError] =  useState("");

        
    
        const handleSubmit =  async (e) => {
            e.preventDefault();
           
            let email = e.target.email.value;
            let button = e.target.button;
           
            button.innerHTML= "Submitting...";

            if(!validator.isEmail(email)){
                      setError("Invalid email")
                      return
            }
          
            await emailjs.sendForm("gmail", process.env.REACT_APP_TEMPLATE_ID1, e.target, process.env.REACT_APP_API_KEY)
              .then(() => {
                   
                swal("Success","Email Sent","success");
              }, (error) => {
                  swal("Danger" , error , "danger")
                 
              })
         
             .finally(() => {
              button.innerHTML= "Submit";
              formRef.current.reset()
              setError("")
              
              })
           
           
            
        }
    

    return (
        <div className="app">
        <div className="page-title titl-2">
    <div className="container">
        <div className="row">
            <h2>Contact Us</h2>
            {/* <p><Link style={{color:"#fff" , textDecoration:"none"}} to ="/"> Home </Link> <i className="fas fa-angle-double-right"></i> Contact </p> */}
        </div>
    </div>
</div>

{/* <div style={{marginTop:"0px"}} className="row ">
        
        <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=rivers%20state%20nigeria+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" width="6000" height="450" frameBorder="0" style={{border:"0"}} allowFullScreen></iframe>
        


      </div> */}

   
{/* <div className="row contact-rooo ">
        <div className="container">
           <div className="row">
               
          
            <div  className="col-sm-7">
            <h2 style={{formSize:"18px"}}>Contact Form</h2>
            <form  onSubmit={handleSubmit}> 
                <div className="row cont-row">
                    <div  className="col-sm-4"><label>Enter Name </label><span>:</span></div>
                    <div  className="col-sm-7"><input type="text" name="name" placeholder="Enter Name"  required className="form-control input-sm" / ></div>
                </div>
                <div  className="row cont-row">
                    <div  className="col-sm-4"><label>Email Address </label><span>:</span></div>
                    <div className="col-sm-7"><input type="text" name="email"  required placeholder="Enter Email Address" className="form-control input-sm"  /> <span style={{fontWeight:"bold" , color:"red"}} >{error}</span></div>
                    
                </div>
                 <div  className="row cont-row">
                    <div className="col-sm-4"><label>Mobile Number</label><span>:</span></div>
                    <div className="col-sm-7"><input type="text" name="phone"  required placeholder="Enter Mobile Number" className="form-control input-sm"   / ></div>
                </div>
                 <div  className="row cont-row">
                    <div  className="col-sm-4"><label>Enter Message</label><span>:</span></div>
                    <div className="col-sm-7">
                      <textarea rows="5" name="message" required placeholder="Enter Your Message" className="form-control input-sm"></textarea>
                    </div>
                </div>
                 <div style={{marginTop:"10px"}} className="row">
                    <div style={{paddingTop:"10px"}} className="col-sm-4"><label></label></div>
                    <div className="col-sm-7">
                    <button  name="button" type="submit" style={{backgroundColor:"maroon"}} className="btn btn-primary">Submit</button>
                    </div>
                </div>
                </form>
            </div>
             <div className="col-sm-5">
                    
                  <div style={{margin:"50px"}} className="serv"> 
                
               
             
                              
              
          <h2 style={{marginTop:"10px"}}>Address</h2>

          No 30 Mercy Njoku Plaza Umuebulu, <br /><br />
    RiversState, NigerIa.        
            <br /><br />
       <i className=" fa fa-phone" />  +234 803 933 0949, +234 905 697 8554<br /><br />
       <i className="fa fa-envelope" /> admin@rexiamglobalservices.com<br /><br />
       <i className="fa fa-globe"  /> www.rexiamglobalserviceslimited.com<br /><br />

 
       
            
                
                
              
           </div>    
                
             
         </div>

            </div>
        </div>
        
      </div> */}

      <div className="content">
    
    <div className="container">
      <div className="row">
        <div className="col-md-5 mr-auto">
          <h2>Contact Us</h2>
          <p className="mb-5">The first step in any great partnership is an honest conversation.Get future-ready enterprise information technology solutions and services.
Contact us.</p>

          

<br />
<i className=" fa fa-phone" />  +234 803 933 0949, +234 905 697 8554<br /><br />
<i className="fa fa-envelope" /> rexiamgsltd@gmail.com<br /><br />
<i className="fa fa-globe"  /> www.rexiamglobalservices.com<br /><br />

        </div>

        <div className="col-lg-6 col-md-12">
          <form className="mb-5"onSubmit={handleSubmit} ref={formRef}>
            <div className="row">
              
              <div className="col-md-12 form-group">
                <label htmlFor="name" className="col-form-label">Name</label>
                <input type="text" className="form-control" name="name" required />
              </div>
            </div>
            <div className="row">
              
              <div className="col-md-12 form-group">
                <label htmlFor="name" className="col-form-label">Mobile Number</label>
                <input type="text" className="form-control" name="phone" required  />
              </div>
            </div>
            
            <div className="row">
              <div className="col-md-12 form-group">
                <label htmlFor="email" className="col-form-label">Email</label>
                <input type="text" className="form-control" name="email" required  /> <span style={{fontWeight:"bold" , color:"red"}} >{error}</span>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12 form-group">
                <label htmlFor="message" className="col-form-label">Message</label>
                <textarea className="form-control" name="message"required cols="30" rows="7"></textarea>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4">
                <button  name="button" type="submit" class="btn btn-primary rounded-0 py-2 px-4" style={{backgroundColor:"maroon"}} > Submit </button>
                <span className="submitting"></span>
              </div>
            </div>
          </form>

         
        </div>
      </div>

  </div>
   </div>
   </div>
    )
}

export default Contact
