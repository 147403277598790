import React,{useState} from 'react';
import '../App.css';
import emailjs from 'emailjs-com';
import validator from "validator"
import swal from 'sweetalert';

 const SignUp=( ) => {
  const [error , setError] =  useState("");
   const handleForm = (e) => {
    e.preventDefault();
    let email = e.target.email.value;
    let button = e.target.button;
   
    button.innerHTML= "Submitting...";

    if(!validator.isEmail(email)){
              setError("Invalid email")
              return
    }
     
    emailjs.sendForm("gmail",  process.env.REACT_APP_TEMPLATE_ID2, e.target,  process.env.REACT_APP_API_KEY)
      .then(() => {

        swal("Success","Email Sent","success");
      }, (error) => {

          swal("Danger" , "Error Sending Message" , "danger")
      })
 
      e.target.reset();
    button.innerHTML= "Submit";
     
   }
    
  
  
  return <>
     

    
     <div className="testbox">
    <form onSubmit={handleForm} className="form" >
      <div class="banner">
        <h1> Enquiry</h1>
        
      </div>
      {/* <div className="item">
        <p>Title of Research Proposal</p>
        <input type="text" name="name"/>
      </div> */}
     
      <h5> Details:</h5>
      <div className="item">
        <p>Full Name<span className="required">*</span></p>
        <input type="text" name="name" required/>
      </div>
      {/* <div class="item">
        <p>Mailing Address<span className="required">*</span></p>
        <input type="text" name="name" placeholder="Street address" required/>
        <input type="text" name="name" placeholder="Street address line 2" required/>
        <div class="city-item">
          <input type="text" name="name" placeholder="City" required/>
          <input type="text" name="name" placeholder="Region" required/>
          <input type="text" name="name" placeholder="Postal / Zip code" required/>
          <select className="select" required>
            <option value="">Country</option>
            <option value="1">Russia</option>
            <option value="2">Germany</option>
            <option value="3">France</option>
            <option value="4">Armenia</option>
            <option value="5">USA</option>
          </select>
        </div>
      </div> */}
      <div class="item">
        <p>Phone<span className="required">*</span></p>
        <input type="tell" name="phone" required/>
      </div>
      {/* <div className="item">
        <p>Fax</p>
        <input type="text" name="name" />
      </div> */}
      <div class="item">
        <p>Email<span className="required">*</span></p>
        <input type="email" name="email" required/>
        <span style={{fontWeight:"bold" , color:"red"}} >{error}</span>
      </div>
      {/* <h5>2. Co-Investigator:</h5>
      <div className="item">
        <p>Name and Credentials<span class="required">*</span></p>
        <input type="text" name="name" required/>
      </div>
      <div className="item">
        <p>Mailing Address<span className="required">*</span></p>
        <input type="text" name="name" placeholder="Street address" required/>
        <input type="text" name="name" placeholder="Street address line 2" required/>
        <div class="city-item">
          <input type="text" name="name" placeholder="City" required/>
          <input type="text" name="name" placeholder="Region" required/>
          <input type="text" name="name" placeholder="Postal / Zip code" required/>
          <select className="select" required>
            <option value="">Country</option>
            <option value="1">Russia</option>
            <option value="2">Germany</option>
            <option value="3">France</option>
            <option value="4">Armenia</option>
            <option value="5">USA</option>
          </select>
        </div>
      </div>
      <div className="item">
        <p>Phone<span className="required">*</span></p>
        <input type="text" name="name" required/>
      </div>
      <div className="item">
        <p>Fax</p>
        <input type="text" name="name" />
      </div>
      <div className="item">
        <p>Email<span className="required">*</span></p>
        <input type="text" name="name" required/>
      </div> */}
      {/* <h5>3. Institute Member</h5> */}
      <div className="question">
        <p>Individual/Company<span className="required">*</span></p>
        <div className="question-answer">
          <input type="radio" value="Individual" id="radio_1" name="firm" required/>
          <label htmlFor="radio_1" class="radio"><span>Individual</span></label>
          <input type="radio" value="Company" id="radio_2" name="firm" required/>
          <label htmlFor="radio_2" class="radio"><span>Company</span></label>
        </div>
      </div>
      {/* <div className="question">
        <p>Co-Investigator:<span class="required">*</span></p>
        <div class="question-answer">
          <input type="radio" value="none" id="radio_3" name="co-investigator" required/>
          <label for="radio_3" class="radio"><span>Yes</span></label>
          <input type="radio" value="none" id="radio_4" name="co-investigator" required/>
          <label for="radio_4" class="radio"><span>No</span></label>
        </div>
      </div> */}
      {/* <h5>4. Have you applied for or are you now receiving funding support for this research?</h5>
      <div className="question">
        <p><span class="required">*</span></p>
        <div className="question-answer">
          <input type="radio" value="none" id="radio_5" name="research" required/>
          <label for="radio_5" className="radio"><span>Yes</span></label>
          <input type="radio" value="none" id="radio_6" name="research" required/>
          <label for="radio_6" className="radio"><span>No</span></label>
        </div>
      </div>
      <h5>5. IRB:</h5>
      <div className="question">
        <p>Have you applied for IRB review:</p>
        <div className="question-answer">
          <input type="radio" value="none" id="radio_7" name="IRB"/>
          <label for="radio_7" className="radio"><span>Yes</span></label>
          <input type="radio" value="none" id="radio_8" name="IRB"/>
          <label for="radio_8" className="radio"><span>No</span></label>
        </div>
      </div>
      <h5>6. Students only:</h5>
      <div className="item">
        <p>Name of research advisor:</p>
        <input type="text" name="name" />
      </div>
      <div className="item">
        <p>Include a letter of support from advisor in application packet.<span className="required">*</span></p>
        <textarea rows="3" required></textarea>
      </div>
      <div class="question">
        <p>Research Application Checklist:<span className="required">*</span></p>
        <small>Please include the following in your application.</small>
        <div class="question-answer checkbox-item">
          <div>
            <input type="checkbox" value="none" id="check_1" name="checklist" required/>
            <label for="check_1" className="check"><span>Proposal Cover Form</span></label>
          </div>
          <div>
            <input type="checkbox" value="none" id="check_2" name="checklist" required/>
            <label for="check_2" className="check"><span>Abstract</span></label>
          </div>
          <div>
            <input type="checkbox" value="none" id="check_3" name="checklist" required/>
            <label for="check_3" className="check"><span>Narrative</span></label>
          </div>
          <div>
            <input type="checkbox" value="none" id="check_4" name="checklist" required/>
            <label for="check_4" className="check"><span>Budget and Budget Justification</span></label>
          </div>
          <div>
            <input type="checkbox" value="none" id="check_5" name="checklist" required/>
            <label for="check_5" className="check"><span>Timeframe</span></label>
          </div>
          <div>
            <input type="checkbox" value="none" id="check_6" name="checklist" required/>
            <label for="check_6" className="check"><span>References</span></label>
          </div>
          <div>
            <input type="checkbox" value="none" id="check_7" name="checklist" required/>
            <label for="check_7" className="check"><span>Appendices</span></label>
          </div>
          <div>
            <input type="checkbox" value="none" id="check_8" name="checklist" required/>
            <label for="check_8" className="check"><span>Bio Sketch</span></label>
          </div>
          </div>
        </div> */}
        <br />
        {/* <div className="question">
          <p>If funding is approved I agree to do the following:<span class="required">*</span></p>
          <div class="question-answer checkbox-item">
            <div>
              <input type="checkbox" value="none" id="check_9" name="check" required/>
              <label for="check_9" class="check"><span>I agree to the <a href="https://www.w3docs.com/privacy-policy">terms of service.</a></span></label>
            </div>
          </div>
        </div> */}
        <div className="item">
          <p>Message<span class="required">*</span></p>
          <textarea rows="3" required name="message"></textarea>
        </div>
        <div class="btn-block">
          <button name="button" className="button" type="submit" >Submit</button>
        </div>
    </form>
    </div>
             
  </>
}
export default SignUp
