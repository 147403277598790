import React from 'react'
import {Link} from "react-router-dom";
import Features from '../components/Features/Features';
import HomeAbout from '../components/HomeAbout/HomeAbout';
import Progress from "../components/Progress/Progress";
import Mission from '../components/Mission/Mission';



const About = () => {
    return (
        <div className="app" >
        <div className="page-title titl-2">
        <div className="container">
            <div className="row">
                <h2>About Us</h2>
                {/* <p><Link className="link" style={{color:"#fff" }} to ="/"> Home </Link> <i className="fas fa-angle-double-right"></i> About US </p> */}
            </div>
        </div>
    </div>
    <HomeAbout />
    <Progress />
    <Features />
    <Mission />
    </div>
    )
}

export default About
