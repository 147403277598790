import React,{useState , useEffect} from 'react';
import {NavLink } from 'react-router-dom';
import axios from "../../axios"
import {FiAlignRight,FiXCircle } from "react-icons/fi";
import "./Nav.css"
import "../../App.css"


const Nav = () => {
    const [categories , setCategories] = useState([])
    const [loading , setLoading] = useState(true)
    useEffect(()=>{
      const getCategories = async () => {
        
        try {
           const res = await axios.get("/category");
            // console.log(res.data)
            const {data} = res.data
            setCategories(data)
            setLoading(false)
           
        } catch (err) {
            console.log(err)
          
        }
    }
    getCategories()
    },[])
    const [isMenu, setisMenu] = useState(false);
    const [isResponsiveclose, setResponsiveclose] = useState(false);
    const [isMenuSubMenu, setMenuSubMenu] = useState(false);
    const toggleClass = () => {
      setisMenu(isMenu === false ? true : false);
      setResponsiveclose(isResponsiveclose === false ? true : false);
      setMenuSubMenu(isMenuSubMenu === true && false );
  };

    let boxClass = ["main-menu menu-right menuq1"];
    if(isMenu) {
        boxClass.push('menuq2');
    }else{
        boxClass.push('');
    }

    
      
    const toggleSubmenu = () => {
      setMenuSubMenu(isMenuSubMenu === false ? true : false);
    };
    
    let boxClassSubMenu = ["sub__menus"];
    if(isMenuSubMenu) {
        boxClassSubMenu.push('sub__menus__Active ');
    }else {
        boxClassSubMenu.push('');
    }

   

    return (
    <header className="header__middle">
        <div className="container">
            

                {/* Add Logo  */}
                <div className="header__middle__logo">
                    <NavLink  to="/">
                    <img src="/Logo/REX.png" alt="REXIAM" />
                    {/* http://rexiamglobalservices.com/images/LOGO%20REXIAM.png */}
                    </NavLink>
                </div>
                 
                <div className="header__middle__menus">
                    <nav className="main-nav " >
                          
                    {/* Responsive Menu Button */}
                    {isResponsiveclose === true ? <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > <FiXCircle />   </span>
                    </> : <> 
                        <span className="menubar__button" style={{ display: 'none' }} onClick={toggleClass} > < FiAlignRight />   </span>
                    </>}


                     <ul className={boxClass.join(' ')}>
                    <li    className="menu-item" >
                        <NavLink exact activeClassName='is-active' onClick={toggleClass} to={`/`}> Home </NavLink> 
                    </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/about`}> About </NavLink> </li>
                    <li onClick={toggleSubmenu} className="menu-item sub__menus__arrows" > <a className="service " > Services {!loading && (<i className='fas fa-chevron-down' style={{fontSize:"15px"}} />)}  </a>
                        <ul className={boxClassSubMenu.join(' ')} > 
                        {categories.map((item , index)=>{
                            return( <li key={item._id}> <NavLink   onClick={toggleClass} activeClassName='is-active'  to={`/category/${item._id}/${item.title}`}> {item.title} </NavLink> </li>
                                 
                            )
                        })}
                            {/* <li> <NavLink onClick={toggleClass} activeClassName='is-active'  to={`/category/SMART HOME AUTOMATION`}> Smart Home Automation </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/category/ACCESS CONTROL`}> Access Control </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/category/POWER SOLUTION`}> Power Solution </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/category/TRACKING SYSTEM`}> Tracking System </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/category/CCTV`}> CCTV </NavLink> </li>
                            <li><NavLink onClick={toggleClass} activeClassName='is-active' to={`/category/IT`}> Information Technology </NavLink> </li> */}
                        </ul>
                    </li>
                    <li className="menu-item " ><NavLink onClick={toggleClass} activeClassName='is-active' to={`/Contact`}> Contact </NavLink> </li>

                    </ul>


                    </nav>     
                 



        
        
            </div>
	    </div>
    </header>
    )
}

export default Nav
