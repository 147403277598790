import React from 'react'
import "../../App.css"
import {Link} from "react-router-dom";
import {useLocation} from "react-router-dom";
// import Aos from "aos";
// import "aos/dist/aos.css"


const HomeAbout = () => {
//     useEffect(()=>{
// Aos.init({duration:2000})
//     },[])
    const location = useLocation();
    const current = location.pathname;
    
    return (
        <section className="with-medical">
        <div className="container">
            <div className="row">
                
                <div className="col-lg-6 col-md-12 " >
                
                    <img src ="images/solution.svg" alt="" style={{paddingRight:"80px"}} />
                  
                </div>
              
                <div className="col-lg-6 col-md-12  txtr " style={{paddingTop:"80px"}} >
                    <h3> <span>Why Choose Us 
                       </span>
                    </h3>
                    <p>
                    REXIAM (RGSL) is a leading technology and automated systems services provider for commercial and residential facilities: offering personalized and unified solution systems to automate and control connected systems, IT security, and Surveillance.
We are involved in the construction and interior design of commercial and residential buildings. </p>

<p>Also, We are in businesses of Real Estate Development, Marketing, Trading, and Manufacturers’ Representatives to meet the needs of our esteemed clients. </p>

                     
                        {current === "/" ? (<Link to="/about" className="primary link">Read More</Link>) :(
                          <p>
                             Rexiam global services limited (RGSL) expresses the fundamental values and long-term objectives that apply to all employees. In professional activities, every employee should think and act by this company policy to achieve the aim we all share: business success.
                          </p>

                        )}
                       
                       
                </div>

            </div>
        </div>
    </section>
    )
}

export default HomeAbout
