import React , {useState , useEffect}  from 'react'
import { useParams , useHistory } from 'react-router';
import Loading from "../components/Loader/Loader";
import "../App.css"
import {Link} from "react-router-dom";
import axios from "../axios"





const ServiceItem = () => {
    const { id} =useParams();
    const history = useHistory();

   

    const [service , setService] =  useState({});
    const [loading , setLoading] = useState(true)
       
    useEffect(()=>{
        const getService = async () => {
          
          try {
             const res = await axios.get(`/service/${id}`);
            //   console.log(res.data)
            const {data} = res.data
              if(!data)
              {
                  history.push("/");
              }
             
              setService(data)
              setLoading(false)
             
          } catch (err) {
              console.log(err)
            
          }
      }
      getService()
      },[history , id])

    

    //  setService(item);

    
    if(loading){
        return (<Loading/>)
    }


     const { title , image , desc} = service

    
    return (
        <>
        <div class="page-title titl-2">
        <div class="container">
            <div class="row">
                <h2>Solution</h2>
                <p><Link style={{color:"#fff" , textDecoration : "none"
             }} to ="/"> Services </Link> <i class="fas fa-angle-double-right"></i> {title} </p>
            </div>
        </div>
    </div>
        <section className="with-medical">
        <div className="container">
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <img src={image} alt={title} width="400" height="300" />
                    <br />
                </div>
                <br />
                <br />
                <h4> <br />
                        <span>{title}</span>
                    </h4>

                    <p>{desc} </p>
                

            </div>
        </div>
    </section>
    </>
    )
}

export default ServiceItem
