import React from 'react'

const Mission = () => {
  return (
    <div className='key-features container-fluid'>


        <div className='container'>

           <div className='session-title row '>
               <h1 > Our Mission and Company Policy</h1>
               {/* <div className="underline"></div> */}
                <span className='text'>  Our roots run deep, our future is bright,
                  As an Indigenous company, we're known for doing it right.
                  Our tradition of excellence is the foundation we build upon,
                While our innovative solutions keep us moving on.
                With a reliable and solid approach, we're always on hand,
                To meet the needs of our
                 customers and partners, and help them expand." This helps to achieve a high level of customers satisfaction.
                 We produce earnings and growth through reliable products and services, general supplies, 
                construction, security, Real Estate, leasing, Information & Communication Technology, Oil & 
                Gas, Marketing and Consultation strategies geared to the long term, a cost-conscious 
                organizational structure, and demand-centric market access.
                 Our market position is not a given, it's a carving. And we're not just carving, we're strengthening it with strategic acquisitions.
                  Our performance generates trust from customers, shareholders, and employees alike, thanks to our tangible warmth and clear communication.
                 We don't just work for the company, we identify with its mission and values, and always strive for excellence.</span>

                <span className='text'>
                At Rexiam global services limited (RGSL),we don't just have a mission statement - we have fundamental values and long-term objectives that guide us in everything we do. We expect all employees to embody these values and work towards our shared goal: business success. 
                So, whether you're in sales or HR, 
                always remember to think and act in line with our company policy. Because at RGSL, success isn't just a goal it's a way of life

                </span>

                    </div>

            </div>
        
       
        
        
        </div>
  )
}

export default Mission