  import React from 'react'
  import "../../App.css"

  
  const Carousal = () => {
   
      return (
          <>
        <div className="with-medical">
        <div className="container">
        <div className="session-title row">
                    <h2>Our Partners</h2>
                    <div className="underline"></div>
                </div>
         <div className="row">
        
         <div className="col-md-3  ">
       <img src ="Logo/control4.png" className="carousal_client" alt=""   />
       </div>
       <div className="col-md-2 ">
       <img src ="Logo/tuya.png"  className="carousal_client"  alt="" />
       </div>
       <div className="col-md-2 ">
       <img src ="Logo/samsung.png"  alt="" />
       </div>
       <div className="col-md-3">
       <img src ="Logo/hikvision.png"  className="carousal_client"  alt="" />
       </div>
       <div className="col-md-2">
       <img src ="Logo/lorex.png"   className="carousal_client"  alt="" />
       </div>
         </div>
       
                
               
                
                   
                
            {/* <div class="key-row row">
                <div class="col-md-4">
                    <div class="key-single">
                    <img scr="images/perimeter.jpg" alt="" />
                        
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="key-single">
                    <img scr="Logo/lbs.png" alt=""/>
                        
                </div>
                </div>
                <div class="col-md-4">
                    <div class="key-single">
                    <img scr="Logo/rmc.png" alt="" />
                       
                    </div> */}
                {/* </div> */}
               
            {/* </div> */}
        </div>
    </div>
          {/* <div className="container">

           <img scr={logo} alt=""/>
                
           
          <Slider {...settings}>
         
            <img scr="/images/LOGO REXIAM.png" alt="" />
            
          
            <img scr="Logo/lbs.png" alt=""/>
            
          
           
            <img scr="Logo/pisteuo.png"  alt=""/>
           
         
            <img scr="Logo/rmc.png" alt="" />
           
        </Slider>
             
          </div> */}
          </>
      )
  }
  
  export default Carousal
  