import axios from "axios"

const instance = axios.create({
    baseURL:"https://api.rexiamglobalservices.com/api" , 

    
    

});
export default instance;
