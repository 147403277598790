import React from 'react';
import { Link } from 'react-router-dom';
// import { LazyLoadImage } from 'react-lazy-load-image-component'
// import "react-lazy-load-image-component/src/effects/blur.css";

function CardItem({title , image , category , props , _id}) {
  return (
    <>
      <li className='cards__item' >
        <Link to = {`/${props}/${_id}/${title}`} className='cards__item__link' >
          <figure className='cards__item__pic-wrap' data-category={category}>
            <img
              className='cards__item__img'
              alt={title}
              src={image}
            />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{title}</h5>
          </div>
        </Link>
      </li>
    </>
  );
}

export default CardItem;
