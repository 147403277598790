import React,{useState , useEffect} from 'react';
import axios from "../../axios"
import './Cards.css';
import CardItem from './CardItem';
import {Col} from "react-bootstrap";
// import Aos from "aos";
// import "aos/dist/aos.css"


function Cards() {
  const [categories , setCategories] = useState([])
  useEffect(()=>{
    const getCategories = async () => {
      
      try {
         const res = await axios.get("/category");
          const {data} = res.data
          setCategories(data)
         
      } catch (err) {
          console.log(err)
        
      }
  }
  getCategories()
  },[])
        let columns = [];
        categories.map((item)=>{
                         
                         
          columns.push(
            <div className="col-md-4 mb=4" key={item._id}  >
            <CardItem 
             {...item} props="category" category={item.title}
            />
            </div>
          )
       
          return null
          
          })
  return (
    <div className='cards' >
                <div className="session-title row">
                    <h2>OUR SOLUTIONS</h2>
                     <div className="underline"></div>
                </div>
                <div className=' row '>
                  {
                    categories.map((item)=>(
                      
                      <Col lg={4} key={item._id}  className="mb-5">
                      <CardItem 
                       {...item} props="category" category={item.title}
                      />
                      </Col>
                      


                    
                    ))
                }





       
       </div>
       </div>
    
  );
}

export default Cards;
